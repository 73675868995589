<template>
  <CriaPPD/>
</template>

<script>
import CriaPPD from "@/components/ppd/criacao/CriaPPD"; // @ is an alias to /src
export default {
    components: {
        CriaPPD
    }
}
</script>